import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  Session,
  startPersistedSession,
  endPersistedSession,
  getPersistedSession,
} from './session'

export type AuthContextType = {
  isLoading: boolean
  isLoggedIn: boolean
  login: (session: Session) => void
  logout: () => void
}

const initialState: AuthContextType = {
  isLoading: true,
  isLoggedIn: false,
  login: () => undefined,
  logout: () => undefined,
}

export const AuthContext = createContext<AuthContextType>(initialState)

export const AuthProvider: React.FC<{ onSessionTimeout?: () => void }> = ({
  children,
  onSessionTimeout,
}) => {
  const [isLoading, setLoading] = useState(initialState.isLoading)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  /**
   * logout function to be exposed in the context and used in the UI
   */
  const logout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    endPersistedSession()
    setIsLoggedIn(false)
  }, [])

  /**
   * When a token expires, run this automatically
   */
  const onTimeout = useCallback(() => {
    logout()
    onSessionTimeout?.()
  }, [logout, onSessionTimeout])

  /**
   * login function to be exposed in the context and used in the UI
   */
  const login = useCallback(
    (payload: Session) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      const time = new Date(payload.expiresAt).getTime() - Date.now()
      timeoutRef.current = setTimeout(onTimeout, time)
      setIsLoggedIn(true)
      startPersistedSession(payload)
    },
    [onTimeout]
  )

  useEffect(
    function checkSession() {
      const persistedSession = getPersistedSession()
      if (persistedSession) login(persistedSession)
      setLoading(false)
    },
    [login]
  )

  useEffect(function removeTimeout() {
    const timeout = timeoutRef.current

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  return (
    <AuthContext.Provider value={{ isLoading, isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
