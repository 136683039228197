import { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/dist/client/router'
import { useContext, useEffect } from 'react'

import { AuthContext, AuthContextType } from './context'
import { ACCESS_TOKEN_KEY } from './session'

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext)
}

export const getTokenFromCtx = (
  ctx: GetServerSidePropsContext
): { accessToken: string; expiresAt: string; tokenType: string } | null => {
  const token = ctx.req.cookies?.[ACCESS_TOKEN_KEY]

  return token ? JSON.parse(token) : null
}

/**
 * Server-side route-restriction
 * Call this function inside `getServerSideProps` to protect the route
 */
export const restrictRoute = (
  ctx: GetServerSidePropsContext,
  shouldRedirect: (hasSession: boolean) => string | undefined = (hasSession) =>
    !hasSession ? '/login' : undefined
):
  | { redirect: { destination: string; permanent: false } }
  | { props: Record<string, unknown> } => {
  const hasSession = !!getTokenFromCtx(ctx)
  const redirect = shouldRedirect(hasSession)

  if (redirect) {
    return { redirect: { destination: redirect, permanent: false } }
  }

  return { props: {} }
}

/**
 * Client-side route protection
 * Using this hook makes the page load while checking for a session.
 * Make sure you leverage `isLoading` to show appropriate UI
 */
type Args = {
  shouldRedirect?: (isLoggedIn: boolean) => boolean
  redirectTo?: string
}

export const useRestrictedRoute = ({
  shouldRedirect = (isLoggedIn: boolean) => !isLoggedIn,
  redirectTo = '/login',
}: Args = {}): AuthContextType => {
  const router = useRouter()
  const authContext = useAuth()
  const { isLoading, isLoggedIn } = authContext

  useEffect(
    function checkRoute() {
      if (!isLoading && shouldRedirect(isLoggedIn)) {
        router.replace(redirectTo)
      }
    },
    [isLoading, isLoggedIn, shouldRedirect, redirectTo, router]
  )

  return authContext
}
