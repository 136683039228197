export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  opacity: {
    90: 'rgba(15, 45, 67, 0.9)',
    80: 'rgba(15, 45, 67, 0.8)',
    70: 'rgba(15, 45, 67, 0.7)',
    60: 'rgba(15, 45, 67, 0.6)',
    50: 'rgba(15, 45, 67, 0.5)',
    40: 'rgba(15, 45, 67, 0.4)',
    30: 'rgba(15, 45, 67, 0.3)',
    20: 'rgba(15, 45, 67, 0.2)',
    10: 'rgba(15, 45, 67, 0.1)',
    5: 'rgba(15, 45, 67, 0.05)',
  },
  gray: {
    900: '#0F2D43',
    800: '#2F495C',
    700: '#4F6676',
    600: '#6F828F',
    500: '#909EA9',
    400: '#B0BAC2',
    300: '#D0D7DB',
    200: '#E0E5E8',
    100: '#F0F3F5',
    50: '#F8FAFB',
  },
  blue: {
    900: '#0F2D43',
    800: '#143852',
    700: '#1A4462',
    600: '#1F4F71',
    500: '#245A80',
    400: '#557F9C',
    300: '#86A4B9',
    200: '#B6C8D5',
    100: '#E7EDF1',
  },
  orange: {
    900: '#331B14',
    800: '#653628',
    700: '#97503C',
    600: '#C86B50',
    500: '#FA8564',
    400: '#FB9E83',
    300: '#FCB6A2',
    200: '#FDCFC1',
    100: '#FEE7E0',
  },
  green: {
    900: '#0E3333',
    800: '#144646',
    700: '#195A5A',
    600: '#1F6D6D',
    500: '#248080',
    400: '#539B9B',
    300: '#82B7B7',
    200: '#B1D2D2',
    100: '#E0EDED',
  },
  pink: {
    900: '#331424',
    800: '#512039',
    700: '#6F2C4E',
    600: '#8D3863',
    500: '#AB4478',
    400: '#BE6E96',
    300: '#D197B4',
    200: '#E3C1D2',
    100: '#F6EAF0',
  },
  yellow: {
    900: '#332812',
    800: '#654F24',
    700: '#977636',
    600: '#C89D47',
    500: '#FAC459',
    400: '#F9CC70',
    300: '#F8D487',
    200: '#F6DC9E',
    100: '#F5E3B5',
    50: '#FCF7E9',
  },
  red: {
    900: '#331817',
    800: '#642F2D',
    700: '#944744',
    600: '#C55E5A',
    500: '#F57570',
    400: '#F79490',
    300: '#FAB3B1',
    200: '#FCD2D1',
    100: '#FEF1F1',
  },
}
