import { ChakraProvider } from '@chakra-ui/react'
import { AppProps } from 'next/app'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { theme } from 'common/UI'
import en from 'lib/i18n/en.json'
import { AuthProvider } from 'modules/auth'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const messages: Record<string, Record<string, string>> = {
  en,
}

const queryClient = new QueryClient()

const anonymousRoutes = ['/call/[token]']

const MyApp = ({ Component, pageProps, router }: AppProps): JSX.Element => {
  const defaultLocale = router.defaultLocale || 'en'
  const locale = router.locale || defaultLocale

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages[locale] || {}}
    >
      <ChakraProvider resetCSS theme={theme}>
        <QueryClientProvider client={queryClient}>
          {anonymousRoutes.includes(router.pathname) ? (
            <Component {...pageProps} />
          ) : (
            <AuthProvider
              onSessionTimeout={() => {
                queryClient.clear()
                router.push('/login')
              }}
            >
              <Component {...pageProps} />
              {process.env.NEXT_PUBLIC_DEBUG_QUERY === 'true' && (
                <ReactQueryDevtools />
              )}
            </AuthProvider>
          )}
        </QueryClientProvider>
      </ChakraProvider>
    </IntlProvider>
  )
}

export default MyApp
