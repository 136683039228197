import { transparentize } from '@chakra-ui/theme-tools'

const Badge = {
  baseStyle: {
    fontWeight: 'medium',
    borderRadius: 'md',
  },
  sizes: {
    lg: {
      fontSize: 'sm',
      py: '1',
      px: '2',
    },
  },
}

const Button = {
  baseStyle: {
    fontWeight: 'md',
    borderRadius: 'base',
  },
  variants: {
    line: {
      border: '1px',
      borderColor: 'gray.200',
      _hover: {
        bg: 'gray.100',
      },
    },
    nav: {
      p: '2',
      bg: 'transparent',
      _hover: {
        color: 'pink.500',
      },
      _focus: {
        color: 'pink.500',
        bg: 'white',
        shadow: 'none',
      },
      _active: {
        color: 'pink.500',
        bg: 'white',
      },
      _disabled: {
        color: 'pink.500',
        opacity: 1,
      },
    },
  },
}

const Container = {
  baseStyle: {
    maxWidth: 'container.xl',
  },
}

const Divider = {
  baseStyle: {
    opacity: 1,
  },
}

const Drawer = {
  baseStyle: ({ theme }: Record<string, any>): Record<string, any> => ({
    overlay: {
      bg: transparentize('gray.900', 0.1)(theme),
    },
    dialog: {
      borderRadius: { base: 'none', lg: 'xl' },
      shadow: 'xl',
      margin: { base: 0, lg: '4' },
    },
  }),
  defaultProps: {
    size: 'lg',
  },
}

const Heading = {
  baseStyle: {
    fontWeight: 'medium',
  },
}

const IconButton = {
  baseStyle: {
    borderRadius: 'none',
  },
}

const Input = {
  defaultProps: {
    focusBorderColor: 'blue.300',
  },
}

const Menu = {
  baseStyle: {
    item: {
      fontWeight: 'md',
      _hover: {
        bg: 'gray.100',
      },
    },
  },
}

const Modal = {
  baseStyle: ({ theme }: Record<string, any>): Record<string, any> => ({
    overlay: {
      bg: transparentize('gray.900', 0.5)(theme),
    },
    dialog: {
      borderRadius: 'xl',
      shadow: 'xl',
    },
    header: {
      fontSize: 'lg',
      fontWeight: 'medium',
    },
  }),
}

const Skeleton = {
  baseStyle: {
    opacity: 0.25,
  },
}

const Table = {
  baseStyle: {
    table: {
      fontSize: 'sm',
      whiteSpace: 'nowrap',
    },
    th: {
      fontWeight: 'medium',
      letterSpacing: '0.025rem',
    },
    tbody: {
      tr: {
        _hover: {
          bg: 'gray.50',
        },
      },
    },
  },
  sizes: {
    sm: {
      th: {
        py: '2',
      },
    },
  },
  variants: {
    simple: {
      th: {
        color: 'gray.400',
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
}

export const components = {
  Badge,
  Button,
  Container,
  Divider,
  Drawer,
  Heading,
  IconButton,
  Input,
  Menu,
  Modal,
  Skeleton,
  Table,
}
