import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'
import { components } from './components'
import { layerStyles } from './layerStyles'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { textStyles } from './textStyles'
import { typography } from './typography'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body, #__next': {
        height: '100%',
      },
      body: {
        bg: 'gray.50',
      },
      '#nprogress': {
        pointerEvents: 'none',
      },
      '#nprogress .bar': {
        background: 'pink.500',
        position: 'fixed',
        zIndex: 'tooltip',
        top: '0',
        left: '0',
        width: '100%',
        height: '3px',
      },
      '#nprogress .peg': {
        display: 'block',
        position: 'absolute',
        right: '0px',
        width: '100px',
        height: '100%',
        opacity: 1,
        WebkitTransform: 'rotate(3deg) translate(0px, -4px)',
        msTransform: 'rotate(3deg) translate(0px, -4px)',
        transform: 'rotate(3deg) translate(0px, -4px)',
      },
    },
  },
  colors,
  components,
  layerStyles,
  shadows,
  sizes,
  textStyles,
  typography,
})
